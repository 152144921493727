import Card from "../components/Card";
import Wooden from "../components/assets/wooden.jpg";
import Wallpaper from "../components/assets/wallpaper.jpg";
import Cladding from "../components/assets/cladding.jpg";
import Grass from "../components/assets/grass.jpg";
import Carpet from "../components/assets/carpet.jpg";
import styled from "styled-components";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 480px) {
    margin-left: 10px;
    width: 250px;
  }
`;

const Title = styled.h2`
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  font-family: poppins;
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: #c3b1e1;
  width: 1200px;
  height: 920px;
  position: absolute;
  top: 500px;
  left: -750px;
  z-index: -77;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const Circle2 = styled.div`
  border-radius: 50%;
  background-color: #ffe5b4;
  width: 750px;
  height: 700px;
  position: absolute;
  top: 50px;
  right: -250px;
  z-index: -77;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const Product = () => {
  return (
    <div
      style={{
        position: "relative",
        overflowX: "hidden",
      }}
    >
      <Navbar />
      <div style={{ height: "80px", position: "relative" }}></div>
      <Title>Our Products</Title>
      <Cards style={{ zIndex: "22" }}>
        <Card title={"Wooden Flooring"} image={Wooden} />
        <Card title={"Wallpaper"} image={Wallpaper} />
        <Card title={"Cladding"} image={Cladding} />
        <Card title={"Artificial Grass"} image={Grass} />
        <Card title={"Carpet"} image={Carpet} />
      </Cards>
      <Circle />
      <Circle2 />
      <Footer />
    </div>
  );
};

export default Product;
