import styled from "styled-components";
import Image1 from "../components/assets/cladding.jpg";
import Pattern from "../components/assets/pattern.png";

const Container = styled.div`
  padding-top: 50px;
  width: 100vw;
  height: calc(100vh + 300px);
  font-family: "Poppins", sans-serif;
  /* background-color: #faf9f6; */
  position: relative;
  @media only screen and (max-width: 480px) {
    height: 900px;
  }
`;

const Title = styled.h2`
  font-size: 40px;
  margin-left: 100px;
  text-align: center;
  @media only screen and (max-width: 480px) {
    font-size: 30px;
    margin-left: 0px;
  }
`;

const SubTitle = styled.h3`
  margin-left: 100px;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  @media only screen and (max-width: 480px) {
    font-size: 15px;
    margin-left: 0px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Left = styled.div`
  flex: 1;
  margin-left: 150px;
  line-height: 1.7;
  z-index: 99;
  @media only screen and (max-width: 480px) {
    margin-left: 0;
  }
`;

const Content = styled.p`
  /* border-style: solid; */
  /* border-width: 10px; */
  background-color: #fffdd0;
  border: solid 15px black;
  padding: 20px 50px;
  width: 400px;
  height: 500px;
  font-size: 20px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  @media only screen and (max-width: 480px) {
    width: 250px;
    padding: 15px;
    margin-left: 15px;
    font-size: 13px;
  }
`;

const Right = styled.div`
  flex: 1;
`;

const Image = styled.img`
  width: 600px;
  height: 440px;
  margin-top: 60px;
  margin-left: -150px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  border: 30px black solid;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const About = () => {
  return (
    <Container>
      <Title>About us</Title>
      <SubTitle>Galaxy Designs- Soul Of Your Home</SubTitle>
      <Wrapper>
        <Left>
          <Content>
            SINCE 2010 WE ARE TRYING OUR BEST as Every bit of Galaxy wood is
            ethically sourced from the world over. We work in harmony with our
            environment. Prudent use of technology and responsible disposal of
            fully recyclable waste ensures a very small carbon footprint. No
            glue is used for installing our floors. Our lacquers and stains are
            water based and do not emit any VOCs after installation, making
            Galaxy Designs one of the “greenest” floors to live upon.
          </Content>
        </Left>
        <Right>
          <Image src={Image1} />
        </Right>
      </Wrapper>
    </Container>
  );
};

export default About;
