import styled from "styled-components";

const Container = styled.div`
  width: 300px;
  height: 440px;
  margin-left: 50px;
  background-color: #dfd9d9;
  border-radius: 20px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  font-family: poppins;
  margin-bottom: 50px;
`;

const Image = styled.img`
  width: 300px;
  height: 300px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;

const Title = styled.h3`
  margin-left: 10px;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
`;

const Button = styled.button`
  padding: 10px;
  width: 150px;
  background-color: teal;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border: none;
  border-radius: 15px;
  margin-left: 80px;
  cursor: pointer;
  transition: 1s ease;
  :hover {
    width: 170px;
    height: 40px;
  }
`;

const Card = ({ image, title }) => {
  return (
    <Container>
      <Image src={image} />
      <Title>{title}</Title>
      <Button>Learn More</Button>
    </Container>
  );
};

export default Card;
