import styled from "styled-components";
import emailjs from "emailjs-com";
const Container = styled.div`
  margin-top: 100px;
  height: 100vh;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: relative;
  font-family: "Poppins", sans-serif;
  position: relative;
  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    height: 950px;
  }
`;
const Title = styled.h1`
  text-align: center;
  margin-bottom: 100px;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  /* position: absolute;
  top: 100px; */
  margin-left: 100px;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 480px) {
    margin: 0;

    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1;
  margin-left: 120px;
  left: 250px;
  position: absolute;
  padding: 20px;
  background-color: #f7f5f1;
  display: flex;
  z-index: 2;
  align-items: center;
  justify-content: flex-end;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  @media only screen and (max-width: 480px) {
    margin: 0;
    left: 0;
    margin-top: 500px;
    padding: 5px;
    margin-left: 25px;
  }
`;

const Form = styled.form`
  /* margin-top: 50px; */
  display: flex;
  flex-direction: column;
  width: 400px;
  @media only screen and (max-width: 480px) {
    width: 250px;
    height: 450px;
  }
`;

const LeftTitle = styled.h3`
  font-size: 25px;
  margin-top: 10px;
  text-align: center;
`;

const Input = styled.input`
  margin-top: 5px;
  padding: 15px;
  background-color: #d4d1d1;
  border: none;
  border-radius: 15px;
  margin-bottom: 20px;

  ::placeholder {
    color: #928e8e;
    font-style: bold;
    font-weight: 700;
  }
  @media only screen and (max-width: 480px) {
    padding: 8px;
  }
`;

const TextArea = styled.textarea`
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #d4d1d1;
  border: none;
  border-radius: 15px;
  padding: 10px;
  ::placeholder {
    color: #928e8e;
    font-style: bold;
    font-weight: 700;
  }
`;

const Right = styled.div`
  flex: 1;
  position: absolute;
  top: 125px;
  left: 800px;
  background-color: teal;
  height: 550px;
  width: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(38, 57, 77) 10px 20px 30px -10px;
  z-index: 5;
  @media only screen and (max-width: 480px) {
    margin: 0;
    left: 0;
    top: 0;
    left: 0;
    width: 250px;
    height: 400px;
    margin-left: 25px;
    margin-top: 50px;
  }
`;

const RightTitle = styled.h3`
  color: white;
  font-size: 30px;
  text-align: center;
`;

const Span = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContactDetail = styled.p`
  color: white;
  @media only screen and (max-width: 480px) {
    font-size: 12px;
  }
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: #008080b0;
  width: 1200px;
  height: 920px;
  position: absolute;
  top: -300px;
  left: -650px;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_oas9k5b",
        "template_3z2c1tl",
        e.target,
        "user_Tf3okcul1UoaFg8MxD9SS"
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Container>
      <Title>Contact us</Title>
      <Wrapper>
        <Left>
          <Form onSubmit={sendEmail}>
            <LeftTitle>Get in touch</LeftTitle>
            {/* <Label>Name</Label> */}
            <Input type="text" placeholder="Name" name="name" />

            {/* <Label>Phone Number</Label> */}
            <Input type="number" placeholder="Phone Number" name="user_phone" />

            {/* <Label>Email</Label> */}
            <Input type="email" placeholder="Email" name="user_email" />

            {/* <Label>Message</Label> */}
            <TextArea name="message" placeholder="Query" rows="4" />

            <Input
              style={{
                padding: "10px",
                backgroundColor: "teal",
                fontSize: "15px",
                fontWeight: "300",
                color: "white",
              }}
              type="submit"
              value="CONNECT NOW"
            />
          </Form>
        </Left>

        <Right>
          <RightTitle>Contact Us</RightTitle>
          <Span>
            <i
              class="fas fa-map-marker-alt"
              style={{
                borderRadius: "50%",
                backgroundColor: "#088F8F",
                color: "white",
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px",
              }}
            ></i>
            <ContactDetail>
              <b>Address:</b>4-7-9/172 <br /> INDIRA NAGAR, <br /> NACHARAM,
              HYDERABAD <br /> 500076
            </ContactDetail>
          </Span>
          <Span>
            <i
              class="fas fa-phone-alt"
              style={{
                borderRadius: "50%",
                backgroundColor: "#088F8F",
                color: "white",
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px",
              }}
            ></i>
            <ContactDetail>
              <b>Phone:</b> +91 9891 797 437
            </ContactDetail>
          </Span>
          <Span>
            <i
              class="fas fa-envelope"
              style={{
                borderRadius: "50%",
                backgroundColor: "#088F8F",
                color: "white",
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px",
              }}
            ></i>
            <ContactDetail>
              <b>Email:</b> sales@galaxydesign.in
            </ContactDetail>
          </Span>
          <Span>
            <i
              class="fas fa-browser"
              style={{
                borderRadius: "50%",
                backgroundColor: "#088F8F",
                color: "white",
                width: "40px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px",
              }}
            ></i>
            <ContactDetail>
              <b>Website:</b> galaxydesign.in
            </ContactDetail>
          </Span>
        </Right>
      </Wrapper>
      <Circle />
    </Container>
  );
};

export default Contact;
