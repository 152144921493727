import React from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/navbar/Navbar";
import Contact from "../../components/Contact";
const Contactpage = () => {
  return (
    <div>
      <Navbar></Navbar>
      <Contact />
      <Footer />
    </div>
  );
};

export default Contactpage;
