import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Map from '../components/assets/map.png';

const Container = styled.div`
  overflow: hidden;
  background-color: black;
  width: 100vw;
  height: 400px;
  font-family: poppins;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Left = styled.div`
  margin-top: 25px;
  margin-left: 50px;
  position: 'relative';
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;
const Logo = styled.span`
  color: white;
  font-family: poppins;
  font-size: 25px;
`;
const Center = styled.div`
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const Right = styled.div`
  background-color: black;
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(38, 57, 77) 10px 20px 30px -10px;
  margin-right: 20px;
`;

const Span = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ContactDetail = styled.p`
  color: white;
  font-size: 14px;
`;

const Image = styled.img`
  width: 300px;
  height: 200px;
`;

const CenterTitle = styled.h2`
  color: white;
  text-align: center;
  margin-top: 50px;
  font-weight: 300;
`;
const RightTitle = styled.h2`
  color: white;
  font-weight: 300;
  margin-top: 50px;
  text-align: left;
  font-size: 20px;
`;

const Div = styled.div`
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

const Footer = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <Logo>Galaxy Designs.</Logo>
          {/* <h4 style={{ color: "white", bottom: "5px", position: "absolute" }}>
            copyright @galaxydesign.in
          </h4> */}
          <Div>
            <h3 style={{ color: 'white', fontWeight: 300, fontSize: '20px' }}>
              Important Links
            </h3>
            <ul
              style={{
                color: 'white',
                listStyle: 'none',
                fontSize: '15px',
              }}
            >
              <li
                style={{
                  color: 'white',
                  marginBottom: '20px',
                }}
              >
                <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                  Home
                </Link>
              </li>
              <li
                style={{
                  color: 'white',
                  marginBottom: '20px',
                }}
              >
                <Link
                  to="/about"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  About
                </Link>
              </li>
              <li
                style={{
                  color: 'white',
                  marginBottom: '20px',
                }}
              >
                <Link
                  to="/product"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  Product
                </Link>
              </li>
              <li
                style={{
                  color: 'white',
                  marginBottom: '20px',
                }}
              >
                <Link
                  to="/contact"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </Div>
        </Left>
        <Center>
          <CenterTitle>Address</CenterTitle>
          <a href="https://g.co/kgs/ZvY7T3">
            <Image src={Map} />
          </a>
        </Center>
        <Right>
          <RightTitle>Contact</RightTitle>
          <Span>
            <i
              class="fas fa-map-marker-alt"
              style={{
                borderRadius: '50%',
                backgroundColor: 'grey',
                color: 'white',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '10px',
              }}
            ></i>
            <ContactDetail>
              <b>Address:</b>4-7-9/172 <br /> INDIRA NAGAR, <br /> NACHARAM,
              HYDERABAD <br /> 500076
            </ContactDetail>
          </Span>
          <Span>
            <i
              class="fas fa-phone-alt"
              style={{
                borderRadius: '50%',
                backgroundColor: 'grey',
                color: 'white',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '10px',
              }}
            ></i>
            <ContactDetail>
              <b>Phone:</b> +91 9891 797 437
            </ContactDetail>
          </Span>
          <Span>
            <i
              class="fas fa-envelope"
              style={{
                borderRadius: '50%',
                backgroundColor: 'grey',
                color: 'white',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '10px',
              }}
            ></i>
            <ContactDetail>
              <b>Email:</b> sales@galaxydesign.in
            </ContactDetail>
          </Span>
          <Span>
            <i
              class="fas fa-browser"
              style={{
                borderRadius: '50%',
                backgroundColor: 'grey',
                color: 'white',
                width: '30px',
                height: '30px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '10px',
                fontSize: '15px',
              }}
            ></i>
            <ContactDetail>
              <b>Website:</b> galaxydesign.in
            </ContactDetail>
          </Span>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Footer;
