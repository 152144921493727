import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Productpage from './pages/Productpage';
import Contactpage from './pages/pages/Contactpage';
import Aboutpage from './pages/pages/Aboutpage';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route exact path="about" element={<Aboutpage />} />
      </Routes>
      <Routes>
        <Route exact path="contact" element={<Contactpage />} />
      </Routes>
      <Routes>
        <Route exact path="product" element={<Productpage />} />
      </Routes>
    </Router>
  );
}

export default App;
