/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import menuItems from './MenuItems';
import './Navbar.css';

const Navbar = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <nav className="navbar">
      <div>
        <h1 className="navbar-logo">
          <Link
            to="/contact"
            style={{ color: 'black', textDecoration: 'none' }}
          >
            Galaxy Designs
          </Link>
        </h1>
        <button className="menu-icon" onClick={handleClick}>
          <i
            style={{ color: 'black' }}
            className={active ? 'fas fa-times' : 'fas fa-bars'}
          ></i>
        </button>
      </div>
      <div className="lists">
        <ul id="menulists" className={active ? 'nav-menu active' : 'nav-menu'}>
          {/* {menuItems.map((item, index) => {
            return (
              <li className="menulist" key={index}>
                <a
                  className="hyperlinkMenu"
                  style={{ color: 'black' }}
                  href={item.url}
                  className={item.cName}
                >
                  {item.title}
                </a>
              </li>
            );
          })} */}
          <Link
            to="/"
            style={{
              color: 'black',
              textDecoration: 'none',
              marginLeft: '20px',
            }}
          >
            <li>Home</li>
          </Link>
          <Link
            to="/about"
            style={{
              color: 'black',
              textDecoration: 'none',
              marginLeft: '20px',
            }}
          >
            <li>About</li>
          </Link>
          <Link
            to="/product"
            style={{
              color: 'black',
              textDecoration: 'none',
              marginLeft: '20px',
            }}
          >
            <li>Product</li>
          </Link>
          <Link
            to="/contact"
            style={{
              color: 'black',
              textDecoration: 'none',
              marginLeft: '20px',
            }}
          >
            <li>Contact</li>
          </Link>
        </ul>
      </div>
      {/* <Button style={{ fontSize: "12px" }}>Contact Now</Button> */}
      <button
        style={{
          padding: '12px',
          backgroundColor: '#3ebd4b',
          border: 'none',
          borderRadius: '10px',
          color: 'white',
          cursor: 'pointer',
        }}
      >
        <Link to="/contact" style={{ color: 'white', textDecoration: 'none' }}>
          Contact Now
        </Link>
      </button>
    </nav>
  );
};

export default Navbar;
