import styled from "styled-components";
import Img1 from "../components/assets/image1.png";
import Img2 from "../components/assets/image2.png";
// import Circle from "./Circle";

const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
  /* background-color: grey; */
  font-family: "Poppins", sans-serif;
  position: relative;
  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const Left = styled.div`
  flex: 1;
  width: 300px;
  margin-left: 80px;
  margin-bottom: 50px;
  @media only screen and (max-width: 480px) {
    margin: 10px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  font-weight: 600;
  @media only screen and (max-width: 480px) {
    font-size: 35px;
  }
`;

const Green = styled.span`
  color: #3ebd4b;
`;

const Subtitle = styled.h2`
  font-weight: 500;
`;

const Paragraph = styled.p`
  width: 500px;
  line-height: 1.7;
  @media only screen and (max-width: 480px) {
    width: 250px;
  }
`;

const Button = styled.button`
  background-color: #139213;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  width: 120px;
  height: 50px;
  z-index: 500;
`;

const Right = styled.div`
  flex: 1;
  z-index: 22;
  position: relative;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const Image1 = styled.img`
  position: absolute;
  top: -300px;
  right: 70px;
`;

const Image2 = styled.img`
  position: absolute;
  top: -70px;
  left: -10px;
`;

const Circle = styled.div`
  border-radius: 50%;
  background-color: #8ff699;
  width: 1200px;
  height: 720px;
  position: absolute;
  top: -200px;
  left: 450px;
  z-index: -100;
  /* @media only screen and (max-width: 480px) {
    top: -400px;
    left: 20px;
  } */
`;

const Intro = () => {
  return (
    <Container>
      <Wrapper>
        <Left>
          <Title>
            Galaxy <Green>Designs. </Green>
          </Title>
          <Subtitle>Soul Of Your Home</Subtitle>
          <Paragraph>
            A Complete one-stop solution for your <b>All</b> interior needs, For
            your Home, Office, balcony, basketball to volleyball, We ensure you
            the best quality product for Next Interior plans.
          </Paragraph>
          <Button style={{ zIndex: "33" }}>Contact Now</Button>
        </Left>
        <Right>
          <Image1 src={Img1} />
          <Image2 src={Img2} />
        </Right>
      </Wrapper>
      <Circle />
    </Container>
  );
};

export default Intro;
