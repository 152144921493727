import Intro from "../components/Intro";
import Contact from "../components/Contact";
import About from "./About";
import Product from "./Product";
import styled from "styled-components";
import Pattern from "../components/assets/pattern.png";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/Footer";
import ResponseAppBar from "../components/ResponseAppBar";

const Container = styled.div`
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  @media only screen and (max-width: 768px) {
    overflow-x: hidden;
  }
`;

const Home = () => {
  return (
    <Container>
      <Navbar style={{ zIndex: -20 }} />
      {/* <ResponseAppBar /> */}
      <Intro />
      <Contact />
      <Product />
      <About />
      <Footer />
    </Container>
  );
};

export default Home;
